import { useEffect } from 'react'
import Sound from '../../assets/audio/onLoadMusic.mp3'

const audio = new Audio(Sound)


const Music = (props) => {


    useEffect(() => {


        audio.play()

    }, [])




return (

    <>
    </>

)

}

export default Music