import './App.css';
import Iframe from '../Iframe/Iframe';
import Music from '../Music/Music';
import { useState } from 'react';


function App() {
  
  const [playMusic, setPlayMusic] = useState(false)


  
  return (
    <div>
      <h1>Discord</h1>
      <Iframe exportSetPlayMusic={setPlayMusic} />
      
      {playMusic && (

        <Music />

      )}

      

    </div>
  );
}

export default App;
