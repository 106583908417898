const Iframe = (props) => {


    setTimeout(() => {

        props.exportSetPlayMusic(true)


    }, 3000)


    return (
        <>
            <iframe src="https://discord.com/widget?id=999395821307043921&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>

        </>
    )

}

export default Iframe